import { template as template_0df02eb04f9f4a6f88109dc33a50e85f } from "@ember/template-compiler";
const SidebarSectionMessage = template_0df02eb04f9f4a6f88109dc33a50e85f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

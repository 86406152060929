import { template as template_8beeb4f6c46b4198994243f89d27654a } from "@ember/template-compiler";
const FKText = template_8beeb4f6c46b4198994243f89d27654a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
